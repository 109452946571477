import React, { useState, useEffect } from 'react';
import TableDashboard from './TableDashboard';
import QueueManagement from './QueueManagement';
import ServiceUpdate from './ServiceUpdate';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { firebaseConfig, vapidKey } from '../config.ts';

const TabNavbar = () => {
  const [activeTab, setActiveTab] = useState('ComponentA');
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [messages, setMessages] = useState([]);
  const navigate = useNavigate();

  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleLogoutClick = () => {
    setShowLogoutModal(true);
  };

  const confirmLogout = () => {
    setShowLogoutModal(false);
    localStorage.clear();
    navigate('/login');
  };

  const handleloginfcm = () => {
    setTimeout(async () => {
      const logintoken = localStorage.getItem('loginToken');
      if (logintoken) {
        const getfcmToken = localStorage.getItem('fcmToken');
        try {
          const resp = await axios.post(
            'https://qosko.in/hotel/api/staff-update-fcm-key',
            { fcm: getfcmToken },
            {
              headers: {
                Authorization: `Token ${logintoken}`,
                'Content-Type': 'application/json',
              },
            }
          );
          console.log('response fcm ', resp.data);
        } catch (error) {
          console.log('error while fcm api call', error);
        }
      }
    }, 2000);
  };

  useEffect(() => {
    handleloginfcm();
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      setMessages((prevMessages) => [...prevMessages, payload]);
    });

    return () => {
      unsubscribe();
      localStorage.removeItem('sentToServer');
    };
  }, []);

  return (
    <div>
      <div className="flex border-b sticky top-0 z-[200] bg-white">
        <button
          className={`py-2 px-4 focus:outline-none ${
            activeTab === 'ComponentA' ? 'border-b-2 border-blue-500' : ''
          }`}
          onClick={() => handleTabChange('ComponentA')}
        >
          Table Dashboard
        </button>
        <button
          className={`py-2 px-4 focus:outline-none ${
            activeTab === 'ComponentB' ? 'border-b-2 border-blue-500' : ''
          }`}
          onClick={() => handleTabChange('ComponentB')}
        >
          Services Update
        </button>
        <button
          className={`py-2 px-4 focus:outline-none ${
            activeTab === 'ComponentC' ? 'border-b-2 border-blue-500' : ''
          }`}
          onClick={() => handleTabChange('ComponentC')}
        >
          Queue List
        </button>
        <button
          className="py-2 px-4 focus:outline-none"
          onClick={handleLogoutClick}
        >
          Logout
        </button>
      </div>

      <div className="p-4">
        {activeTab === 'ComponentA' && <TableDashboard messages={messages} />}
        {activeTab === 'ComponentB' && <ServiceUpdate />}
        {activeTab === 'ComponentC' && <QueueManagement messages={messages} />}
      </div>

      {/* Logout Confirmation Modal */}
      {showLogoutModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-md text-center">
            <h2 className="mb-4 text-xl">Confirm Logout</h2>
            <p className="mb-6">Do you really want to log out?</p>
            <button
              className="bg-red-500 text-white px-4 py-2 mr-2 rounded"
              onClick={confirmLogout}
            >
              Yes, Logout
            </button>
            <button
              className="bg-gray-300 px-4 py-2 rounded"
              onClick={() => setShowLogoutModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TabNavbar;
