import React, { useState, useEffect } from 'react';
import axios from 'axios';

const QueueManagement = ({messages}) => {
  const [queueStarted, setQueueStarted] = useState(false);
  const [queueData, setQueueData] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [loader , setloader] = useState(false)
  const[emptyqueue, setemptyqueue] = useState(false)
  const [showModal, setShowModal] = useState(false);

  const toggleDropdown = (queueId) => {
    setDropdownOpen(dropdownOpen === queueId ? null : queueId);
  };




  const getstatus = localStorage.getItem('status')
  const getloginToken = localStorage.getItem('loginToken')
 

  // Function to fetch queue data from API

  const fetchQueueData = async () => {
    try {
      const response = await axios.get('https://qosko.in/waiting/staff/api/get-active-queue',{
        headers:{
          'Authorization': `Token ${getloginToken}`
        }
      }); // Replace with your API endpoint for getting queue list
      setQueueData(response.data.in_queue);
      console.log('queue api data ',response.data)
      if (response.data.in_queue.length === 0) {
        setemptyqueue(true)
            } else{

              setemptyqueue(false)
            }
    } catch (error) {
      console.error("Error fetching queue data", error);
      // Set dummy data if there's an error
      setQueueData('Something Went wrong...');
    }
  };

  //   if (getstatus) {
  //   fetchQueueData()
  // }

  useEffect(()=>{
     if (getstatus) {
      setQueueStarted(true)
      fetchQueueData()
    }
  },[])
  console.log('messages from props in queue list ', messages[0]?.notification?.body);
  // console.log('messages from props in queue all ', messages);

 useEffect(()=>{
   
    if (messages[0]?.notification?.body === "staff_queue_update") {
      fetchQueueData();
  }
  },[messages])
  // Function to start the queue
  const handleStartQueue = async () => {
    try {
      // Make the API call to start the queue
      await axios.post('https://qosko.in/waiting/staff/api/start-queue', {}, {
        headers: {
          Authorization: `Token ${getloginToken}`,
        },
      }).then((res)=>{
        if (res.data.status === 'success') {
          
          localStorage.setItem('status', res.data.status)
          setQueueStarted(true);
          fetchQueueData(); // Fetch data immediately upon starting the queue
        }
      })
  
    } catch (error) {
      console.error("Error starting the queue", error);
      setQueueStarted(false)
      // Additional error handling can be done here if needed
    }
  };
  
  // Example of how to use the function in a button's onClick
  // <button onClick={handleStartQueue}>Start Queue</button>
  
  // Function to stop the queue
  const handleStopQueue = async () => {
    try {
      const res = await axios.post('https://qosko.in/waiting/staff/api/stop-queue',{},{
        headers:{
          Authorization: `Token ${getloginToken}`,
        }
      }); // Replace with your API endpoint to stop the queue
      console.log("responce stop queue", res.data)
      if(res.data.status === 'success'){
        localStorage.removeItem('status')
        setQueueStarted(false);
        setShowModal(false)
      }
    } catch (error) {
      setQueueStarted(true);
      console.error("Error stopping the queue", error);
    }
  };

  // Action to assign queue using token id
  const handleAssignQueue = async (tokenId, message) => {
    try {
      const response = await axios.post('https://qosko.in/waiting/staff/api/assign-queue', { "user_queue_id":tokenId, "user_action":message },{
        headers:{
          "Authorization": `Token ${getloginToken}`,
          "Content-Type" :'application/json'
        }
      }); 
      console.log("responce Queue action",response.data);
      if (response.data.status === 'success') {
        // fetchQueueData()
      }
    } catch (error) {
      console.error("Error assigning queue", error);
    }
  };

 

  return (
    <div className="p-2 md:p-6 lg:p-8 ">
      {/* Start and Stop Queue Buttons */}
      <div className="mb-4 flex flex-col md:flex-row">
        <button
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2 mb-2 md:mb-0"
          onClick={handleStartQueue}
          disabled={queueStarted}
          hidden={queueStarted}
        >
          Start Queue
        </button>
        <button
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          // onClick={handleStopQueue}
          hidden={!queueStarted}
          onClick={() => setShowModal(true)}

        >
          Stop Queue
        </button>
      </div>

      <div >{queueStarted && emptyqueue && (<div> Queue list is Empty </div>)}</div>

      {/* Queue List */}
      {queueStarted && queueData.length > 0 && (
        <div
        //  className="overflow-x-auto"
         >
          <table className="min-w-full border-collapse border border-gray-200">
            <thead>
              <tr>
                {/* <th className="px-4 py-2 border border-gray-300">Sr. No.</th> */}
                <th className="px-4 py-2 border border-gray-300">Name</th>
                {/* <th className="px-4 py-2 border border-gray-300">Mobile</th> */}
                <th className="px-4 py-2 border border-gray-300">Number of Seats</th>
                <th className="px-4 py-2 border border-gray-300">Token ID</th>
                {/* <th className="px-4 py-2 border border-gray-300">Waiting Time</th> */}
                <th className="px-4 py-2 border border-gray-300">Action</th>
              </tr>
            </thead>
            <tbody>
              {queueData.map((queue, index) => (
                <tr key={index} className={`border-t ${index === 0 ? "bg-gray-200" : ""}`}>
                  {/* <td className={`px-4 py-2 border border-gray-300 ${index === 0 ? 'font-bold text-lg' : ''}`}>
                    {index + 1}
                  </td> */}
                  <td className={`px-4 py-2 border border-gray-300 ${index === 0 ? 'font-bold text-lg' : ''}`}>
                    {queue.name}
                    <div>
                    ({queue.mobile})
                    </div>
                  </td>
                  {/* <td className={`px-4 py-2 border border-gray-300 ${index === 0 ? 'font-bold text-lg' : ''}`}>
                    {queue.mobile}
                  </td> */}
                  <td className={`px-4 py-2 border border-gray-300 ${index === 0 ? 'font-bold text-lg' : ''}`}>
                    {queue.seats}
                  </td>
                  <td className={`px-4 py-2 border border-gray-300 ${index === 0 ? 'font-bold text-lg' : ''}`}>
                    {queue.token}
                  </td>
                  {/* <td className={`px-4 py-2 border border-gray-300 ${index === 0 ? 'font-bold text-lg' : ''}`}>
                    {queue.remaining_time_in_mins} min
                  </td> */}
                  <td className={`px-4 py-2 border border-gray-300 ${index === 0 ? 'font-bold text-lg' : ''}`}>
                    {/* <button
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                      onClick={() => handleAssignQueue(queue.tokenId, 'Assigning queue')}
                    >
                      Assign Queue
                    </button> */}

                    <div className='btn-container relative'>
                 <button
                  className=" text-lg absolute top-[-10px] right-[10px]"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleDropdown(queue.token);
                  }}
                  aria-label={`Options for Table ${queue.token}`}
                  >
                  ⋮
                </button>
                {
                  dropdownOpen === queue.token && (
                    <div className="absolute right-2 top-8 bg-white border rounded shadow-lg z-20">
                      <button
                          onClick={()=> handleAssignQueue(queue.id , 'reminder')}
                          className="block px-4 py-2 text-sm w-full text-left"
                        >
                          Remind
                        </button>
                      <button
                          onClick={() => handleAssignQueue (queue.id , 'no show')}
                          className="block px-4 py-2 text-sm w-full text-left"
                        >
                          No Show
                        </button>
                    </div>

                  )
                }
                  </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}


{showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg mx-4 sm:mx-8 md:max-w-md lg:max-w-lg">
            <h3 className="text-lg md:text-xl font-semibold mb-4 text-center">Confirm Action</h3>
            <p className="mb-6 text-center">
              Are you sure you want to stop the queue?
            </p>

            <div className="flex justify-end space-x-4">
              {/* Cancel button */}
              <button
                className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded w-full sm:w-auto"
                onClick={() => setShowModal(false)} // Close modal
              >
                No
              </button>

              {/* Confirm button */}
              <button
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded w-full sm:w-auto"
                onClick={handleStopQueue}
              >
                Yes, Stop
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QueueManagement;
