export const firebaseConfig = {
  // Your web app's Firebase configuration here
  // See https://firebase.google.com/docs/web/setup#add-sdks-initialize
  apiKey: 'AIzaSyDUWR-EFFe5WYpvge5qG9k-BflYM_D4eOE',
  authDomain: 'qosko-7b830.firebaseapp.com',
  databaseURL: 'https://qosko-7b830.firebaseio.com',
  projectId: 'qosko-7b830',
  storageBucket: 'qosko-7b830.appspot.com',
  messagingSenderId: '394427280970',
  appId: '1:394427280970:web:eaba340f19ba19ae4600b3',
  measurementId: 'G-6NX4NPLMWH'
};

export const vapidKey = 'BHWggio27IOocssbOSyinVoQXgbsopoCupByElZ79wGR2jJZuqzW_72MyiqLj5teWw5ZwmrRWiLw7omXpkxXtJU';
