import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import loginlogo from '../Assets/login_logo.png';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function LoginPage() {
  const [forminfo, setForminfo] = useState({
    username: '',
    password: '',
    showPassword: false,
  });
  const [loginerror, setloginerror] = useState('')

const [loader, setloader] = useState(false)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForminfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const togglePasswordVisibility = () => {
    setForminfo((prev) => ({
      ...prev,
      showPassword: !prev.showPassword,
    }));
  };

const navigate = useNavigate()
  const handleLogin = async () => {
   
    // Create a new FormData instance
    const formData = new FormData();
    formData.append('username', forminfo.username); // Replace with your username or username field
    formData.append('password', forminfo.password);
  
    try {
      setloader(true)
      const response = await axios.post('https://qosko.in/api-token-auth/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      });
      if (response.data.token !== '') {
        setloader(false)
        console.log('Login Success:', response.data.token);
      localStorage.setItem('loginToken',response.data.token)
      navigate('/table')
      }
  
      
    } catch (error) {
      console.error('Login Failed:', error.response.data.non_field_errors);
      if (error.response.data.non_field_errors) {
        setloginerror('Please Enter A Valid username and password')
      }
      setloader(false)
    }
  };
  

  if (loader) {
    return(
      <div style={{display:'flex', height:'100vh', flexDirection:'column', justifyContent:'center'}}>
         <div role="status" style={{display:'flex', justifyContent:'center', alignItems:'center',}}>
      <svg aria-hidden="true" className="inline w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
      </svg>
  </div>
      <div style={{color:'black', alignContent:'center',marginRight:'auto', marginLeft:'auto', paddingTop:'12px'}} >Loading...</div>
      </div>
     

    )
  }


  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-r from-blue-100 to-indigo-200">
      {/* Container */}
      <div className="flex flex-col md:flex-row bg-white rounded-lg shadow-2xl overflow-hidden w-full max-w-5xl">
        
        {/* Login Form Section */}
        <div className="md:w-1/2 p-8 md:p-12">
          <h2 className="text-3xl font-bold text-indigo-600 mb-4">Login</h2>
          {/* <p className="text-sm text-gray-500 mb-6">Login to access your TravelWise account</p> */}

          {/* username Input */}
          <div className="relative mb-6">
            <input
              type="text"
              name="username"
              placeholder="username"
              value={forminfo.username}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-300"
              required
            />
          </div>

          {/* Password Input */}
          <div className="relative mb-6">
            <input
              type={forminfo.showPassword ? 'text' : 'password'}
              name="password"
              placeholder="Password"
              value={forminfo.password}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-300"
              required
            />
            <button
              type="button"
              className="absolute top-3 right-4 text-gray-500 hover:text-indigo-500"
              onClick={togglePasswordVisibility}
            >
              {forminfo.showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>

          {/* Remember Me & Forgot Password */}
            <p className='text-red-600 p-2 '>{loginerror}</p>
          <div className="flex justify-between items-center mb-6">
            <label className="flex items-center text-gray-600">
              <input type="checkbox" className="form-checkbox h-4 w-4 text-indigo-400" />
              <span className="ml-2 text-sm">Remember me</span>
            </label>
            <a href="forgot-password" className="text-sm text-indigo-400 hover:text-indigo-600">Forgot Password?</a>
          </div>
          
          {/* Login Button */}
          <button
            onClick={handleLogin}
            className="w-full bg-indigo-500 text-white py-2 rounded-lg font-semibold hover:bg-indigo-600 transition-colors"
          >
            Login
          </button>

          {/* Sign Up Link */}
          <p className="text-center text-sm text-gray-600 mt-6">
            Don't have an account? <a href="signup" className="text-indigo-400 hover:text-indigo-600">Sign up</a>
          </p>
        </div>

        {/* Right Image Section */}
        <div className="hidden md:flex md:w-1/2 bg-gradient-to-tr from-blue-300 to-indigo-300 items-center justify-center">
          <img
            src={loginlogo}
            alt="Login Illustration"
            className="object-cover h-96 w-96 md:h-full md:w-full p-6 rounded-lg"
          />
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
