// App.js
import React, { useEffect ,useState} from 'react';
import MenuItem from './Components/MenuItem';
import TableDashboard from './Components/TableDashboard';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoginPage from './Components/LoginPage';
import QueueManagement from './Components/QueueManagement';
import TabNavbar from './Components/TabNavbar';
import PrivateRoutes from './Components/PrivateRoutes';





//firebase 
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import { firebaseConfig, vapidKey } from './config.ts';
import InstagramPost from './SocialMedia/InstagramPost.js';

const App = () => {


    
  // Initialize Firebase App
  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);
    // const [messages, setMessages] = useState([]);
    const [currentToken, setCurrentToken] = useState(null);
    const [permission, setPermission] = useState(Notification.permission);
  
  useEffect(()=>{
     const handlefcm = async() =>{
    const resetUI = () => {
    //   clearMessages();
      setCurrentToken("Loading......"); // Set  instead of "loading..."
      
      // Get registration token
      getToken(messaging, { vapidKey })
          .then((token) => {
              if (token) {
                  sendTokenToServer(token);
                  setCurrentToken(token);
              } else {
                  console.log('No token available, request permission');
                  setPermission(Notification.permission);
              }
          })
          .catch((err) => {
              console.log('Error retrieving token: ', err);
              setCurrentToken(null); // Use null for error case
          });
  };
resetUI()  

const requestPermission = () => {
  console.log('Requesting permission...');
  Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
          console.log('Permission granted!');
          resetUI();
          setPermission('granted');
      } else {
          console.log('Permission denied.'); // Notify the user
          setPermission('denied');
      }
  });
};
requestPermission()

const isTokenSentToServer = () => {
return window.localStorage.getItem('sentToServer') === '1';
};

const setTokenSentToServer = (sent) => {
window.localStorage.setItem('sentToServer', sent ? '1' : '0');
};

const sendTokenToServer = (currentToken) => {
if (!isTokenSentToServer()) {
  console.log('Sending token to server...', currentToken);
  localStorage.setItem('fcmToken',currentToken)
  // TODO: Send the current token to your server.
  setTokenSentToServer(true);

} else {
  console.log('Token already sent to server so won\'t send it again unless it changes');
}
};

  
}

handlefcm()
  },[])
  
  
    
    
   

    return (
        <div>
            <BrowserRouter>
            <Routes>
                <Route path='/insta' element={<InstagramPost/>}/>
                <Route path='/customer/:hotelId' element={<MenuItem/>}/>
                <Route path='/login' element={<LoginPage/>}/>
                <Route path='/' element={<PrivateRoutes/>}>
                <Route path='table' element={<TabNavbar/>}/>
                </Route>
            </Routes>
            </BrowserRouter>


            {/* <Navbar hotel={hotel} /> */}
            {/* <MenuItem  /> */}
            {/* <TableDashboard/> */}
            {/* Other components and content */}

        </div>
    );
};

export default App;
