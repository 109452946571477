import React, { useState, useEffect } from "react";
import axios from "axios"; // Assuming you're using axios for API calls
import {
  FaBroom,
  FaFacebook,
  FaGlassWhiskey,
  FaInstagram,
  FaSnapchat,
  FaToiletPaper,
  FaTwitter,
} from "react-icons/fa";
import { FaPerson } from "react-icons/fa6";
import { FaSync } from "react-icons/fa";

import { GiChiliPepper } from "react-icons/gi";


import { IoClose } from "react-icons/io5";


//firebase 
import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage } from 'firebase/messaging';
import { firebaseConfig } from '../config.ts';
import { useParams } from "react-router-dom";
import SocialMediaPosts from "../SocialMedia/InstagramPost.js";
import Footer from "./Footer.js";
const MenuItem = () => {

  const {hotelId} = useParams()

  const [hotel, setHotel] = useState({
    name: "",
    website: "",
    facebook: "",
    instagram: "",
    snapchat: "",
    twitter: "",
    tableNo: "",
    googlereview: "",
  });
  const [activeCategory, setActiveCategory] = useState("All");
  const [menuItems, setMenuItems] = useState([]);
  const [categories, setCategories] = useState([]);

  const handleLogOut = () =>{
    setloader(true)
      localStorage.clear()
     window.location.reload()
     setloader(false)
  }
  const [isOtpValid, setIsOtpValid] = useState(false);

  useEffect(() => {
    // Check for token in local storage
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setIsOtpValid(true);
      setToken(storedToken);
    }
    const storeTableQueue = localStorage.getItem("tableQueue");
    const storeTableQueueNumber = localStorage.getItem("tablequeuenumber");
    if (storeTableQueue || storeTableQueueNumber) {
      setTableQueueValid(true);
      setQueueToken(storeTableQueueNumber);
    }
    // Simulate an API call for categories and items
    const fetchMenuData = async () => {
      try {
        const response = await axios.get(
          `https://qosko.in/hotel/api/get-hotel-details/${hotelId}`
        ); // Replace with actual API
        const data = response.data;
        console.log("data ", data);

        // Update hotel state with API data
        setHotel({
          name: data.hotel.name,
          website: data.hotel.website,
          facebook: data.hotel.social_media.facebook,
          instagram: data.hotel.social_media.instagram,
          snapchat: data.hotel.social_media.snapchat,
          twitter: data.hotel.social_media.twitter,
          //   tableNo: data.hotel.table_no, // Assuming the API provides table number
          googlereview: data.hotel.reviews.google, // Assuming the API provides googlereview URL
        });

        const apiCategories = data.categories.map((item) => item.name);
        setCategories(["All", ...new Set(apiCategories)]);
        setMenuItems(data.items);
      } catch (error) {
        console.error("Error fetching menu data:", error);
      }
    };

    fetchMenuData();
  }, [isOtpValid]);
  

  const filteredItems =
    activeCategory === "All"
      ? menuItems
      : menuItems.filter((item) => item.category === activeCategory);

  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otp, setOtp] = useState("");
  // const [serviceTimers, setServiceTimers] = useState({});
  const [showQueuePopup, setShowQueuePopup] = useState(false);
  const [name, setName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [tableFor, setTableFor] = useState("");
  const [token, setToken] = useState("");
  const [estimatedTime, setEstimatedTime] = useState("");
  const [otpError, setOtpError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [tableassign, setTableassign] = useState("");
  const [showPaymentPopup, setShowPaymentPopup] = useState(false); // New state for payment popup
  const [billDetails, setBillDetails] = useState(null); // State to hold bill details

  const [isTableQueueValid, setTableQueueValid] = useState(false);
  const [queueToken, setQueueToken] = useState("");
  const [countHead, setcountHead] = useState("");


  const [loader, setloader] = useState(false)

  const handleJoinTable = () => setShowOtpInput(true);

  const handleOtpChange = (e) => setOtp(e.target.value);

 

  const handleOtpSubmit = async () => {
    // Create a JSON object
    const data = {
      otp: otp, // Replace `otp` with the actual value
      hotel_code: `${hotelId}`, // Add hotel_code
    };

    try {
      // Make the POST request with JSON data
      setloader(true)  //loader ui 
      const response = await axios.post(
        "https://qosko.in/guest/api/join-table",
        data,
        {
          headers: {
            "Content-Type": "application/json", // Set the content type to JSON
          },
        }
      );
      console.log("responce ", response.data);

      // Handle the response
      if (response.data.token !== "") {
        setloader(false)     //loader ui
        setIsOtpValid(true);
        setOtpError("");
        setTableassign(response.data.table.number);
        localStorage.removeItem("tableQueue");
        localStorage.removeItem("tablequeuenumber");
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("number", response.data.table.number);
        // window.location.reload()
      } else {
        setOtpError("Invalid OTP, please try again.");
        setloader(false)  // stop loader
      }
    } catch (error) {
      console.error(
        "Error verifying OTP:",
        error.response ? error.response.data : error.message
      );
      setloader(false)  // stop loader
      setOtpError("An error occurred while verifying OTP. Please try again.");
    }
  };

  if (tableassign === "") {
    const tablenumber = Number(localStorage.getItem("number"));
    setTableassign(tablenumber);
  }

  const handleQueueSubmit = async (e) => {
    e.preventDefault();

    // Define a mobile number pattern for validation
    const mobileNumberPattern = /^[0-9]{10}$/;

    // Validate the mobile number
    if (!mobileNumberPattern.test(mobileNo)) {
      setMobileError("Please enter a valid 10-digit mobile number");
      return;
    }

    try {
      // Prepare queue data object
      const queueData = {
        name,
        mobile: mobileNo,
        seats: tableFor,
        hotel_code: `${hotelId}`,
      };

      // Send the POST request
      setloader(true)
      const response = await axios.post(
        "https://qosko.in/waiting/guest/api/join-queue",
        queueData,
        {
          headers: {
            "Content-Type": "application/json", // Set the content type to JSON
          },
        }
      );

      // Clear any existing errors
      setMobileError("");

      // Parse response data and update state
      const responseData = response.data;
      console.log("queue ckeck up ", response.data)
      if (responseData.token !== "") {
        // Set token and estimated time from response
        // setTimeout(() => {
        //   handleQueuefcm()
        // }, 3000);
        handleQueuefcm()
        setloader(false)   //stop loader
        console.log("queue token", responseData.token);
        setQueueToken(responseData.queue.token); // Use the token returned by the response
        localStorage.setItem("tableQueue", responseData.token);
        localStorage.setItem("tablequeuenumber", responseData.queue.token);
        setTableQueueValid(true);
        setEstimatedTime(responseData.queue.remaining_time_in_mins); // This can be dynamically updated if needed
        setcountHead(responseData.queue.count_head)
        // Close the queue popup
        setShowQueuePopup(false);

      }
      
      console.log("Joined Queue:", responseData);
    } catch (error) {
      setloader(false)   //stop loader
      console.error("Error while posting queue data:", error);
      SetQueueError('Queue Off From Management ')

    }
  };

  const localtoken = localStorage.getItem("token");

  const handlePayBill = async () => {
    try {
      const response = await axios.get(
        "https://qosko.in/tables/api/get-table-status",
        {
          headers: {
            Authorization: `Token ${localtoken}`, // Include your token here
          },
        }
      );
      setBillDetails(response.data);
      setShowPaymentPopup(true);
    } catch (error) {
      console.error("Error fetching bill details:", error);
    }
  };

  const handleClosePaymentPopup = () => {
    setShowPaymentPopup(false);
    setBillDetails(null);
  };


  const [serviceTimers, setServiceTimers] = useState({});
  const [rotation, setRotation] = useState(0);
  const [intId, setintId] = useState('');
  const [intId1, setintId1] = useState('');
  const [intId2, setintId2] = useState('');
  const [intId3, setintId3] = useState('');

  // useEffect(() => {
  //   // Start fetching table status on component mount or when the token changes
  //   // fetchTableStatus();

   
  // }, [localtoken]);

  const handleApiCall = async (serviceName) => {

    console.log("service api call",serviceName, serviceTimers)
    try {
      const formData = new FormData();
      formData.append("service", serviceName); // Append the serviceName to FormData

      const response = await axios.post(
        "https://qosko.in/tables/api/call-service",
        formData,
        {
          headers: {
            Authorization: `Token ${localtoken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status === "success") {
        console.log("responce service api",response)
        // Initialize the timer for the service if it doesn't exist
        

        function greet() {
          console.log("Hello!");
          setServiceTimers((prev) => ({
            ...prev,
            [serviceName]: {
              elapsedTime: prev[serviceName]?.elapsedTime ? prev[serviceName].elapsedTime + 1 : 1, // Increment elapsed time if exists, otherwise start from 1
            },
          }));
        }
        
        
        // let intervalId = serviceName == 'waiter' ? setInterval(greet, 1000): '' // Calls greet() every second
        // setintId(intervalId)
        // let intervalId1 = serviceName=='water'? setInterval(greet, 1000): '' // Calls greet() every second
        
        // setintId1(intervalId1)

        if (serviceName === 'waiter') {
          let intervalId = setInterval(greet, 1000); // Calls greet() every second for 'waiter'
          setintId(intervalId); // Store the interval ID for waiter
          console.log('intervalId for waiter: ', intervalId);
        }
      
        if (serviceName === 'water') {
          let intervalId1 = setInterval(greet, 1000); // Calls greet() every second for 'water'
          setintId1(intervalId1); // Store the interval ID for water
          console.log('intervalId1 for water: ', intervalId1);
        }
        if (serviceName === 'tissue') {
          let intervalId2 = setInterval(greet, 1000); // Calls greet() every second for 'water'
          setintId2(intervalId2); // Store the interval ID for water
          console.log('intervalId2 for water: ', intervalId2);
        }
        if (serviceName === 'cleaning') {
          let intervalId3 = setInterval(greet, 1000); // Calls greet() every second for 'water'
          setintId3(intervalId3); // Store the interval ID for water
          console.log('intervalId1 for water: ', intervalId3);
        }
        
      
      }
      
    } catch (error) {
      console.error("Error calling service:", error);
    }
  };

 

  const fetchTableStatus = async () => {
    // console.log('int id',intId,"str",intId1, serviceTimers)
    // clearInterval(intId1)
    // console.log('int id clears',intId,"stuuud",intId1)
    // clearInterval(intId2)
    // clearInterval(intId3)

     
      
    


    setRotation((prev) => prev + 180); // Rotate animation (if any)
    if (localtoken) {
      try {
        const response = await axios.get(
          "https://qosko.in/tables/api/get-table-status",
          {
            headers: { Authorization: `Token ${localtoken}` },
          }
        );

        const services = await response.data.services;
        console.log('refresh ', services);
        
        if (!services.is_waiter_pending) {
          console.log('Clearing waiter timer');
          clearInterval(intId); // Make sure intId is defined earlier
          setServiceTimers((prev) => {
            console.log('Before removing waiter:', prev);
            const updatedTimers = { ...prev };
            delete updatedTimers['waiter'];
            console.log('After removing waiter:', updatedTimers);
            return updatedTimers;
          });
        }
        
        if (!services.is_water_pending) {
          console.log('Clearing water timer');
          clearInterval(intId1); // Ensure intId1 is defined
          setServiceTimers((prev) => {
            console.log('Before removing water:', prev);
            const updatedTimers = { ...prev };
            delete updatedTimers['water'];
            console.log('After removing water:', updatedTimers);
            return updatedTimers;
          });
        }
        
        if (!services.is_tissue_pending) {
          console.log('Clearing tissue timer');
          clearInterval(intId2); // Ensure intId2 is defined
          setServiceTimers((prev) => {
            console.log('Before removing tissue:', prev);
            const updatedTimers = { ...prev };
            delete updatedTimers['tissue'];
            console.log('After removing tissue:', updatedTimers);
            return updatedTimers;
          });
        }
        
        if (!services.is_cleaning_pending) {
          console.log('Clearing cleaning timer');
          clearInterval(intId3); // Ensure intId3 is defined
          setServiceTimers((prev) => {
            console.log('Before removing cleaning:', prev);
            const updatedTimers = { ...prev };
            delete updatedTimers['cleaning'];
            console.log('After removing cleaning:', updatedTimers);
            return updatedTimers;
          });
        }
        

      }
       catch (error) {
        console.error("Error fetching table status:", error);
      }
    }
  };

  const getButtonColor = (elapsedTime) => {
    return elapsedTime >= 1 ? "bg-green-500" : "bg-gray-300"; // Change colors based on elapsed time
  };





  
  
  const [queueError, SetQueueError] = useState('')
  const fetchQueueData = async () => {
    setRotation((prev) => prev + 180); // Rotate animation (if any)
    
    const localQueuetabletoken = localStorage.getItem('tableQueue')
    console.log('localqueue table token', localQueuetabletoken)
    try {
        const response = await axios.get('https://qosko.in/waiting/guest/api/get-queue-status', {
            headers: {
                Authorization: `Token ${localQueuetabletoken}`, 
                // 'Accept': 'application/json', // Optional: Set the Accept header if needed
            },
            params: {
                txt_hotel: `${hotelId}`, // Append the hotel ID as query parameters
            },
        });

        // Update state with the fetched data
        const { token, remaining_time_in_mins, count_head } = response.data;
        console.log('refresh Queue table',response.data)
        setQueueToken(token);
        setEstimatedTime(remaining_time_in_mins);
        setcountHead(count_head)
        // window.location.reload()
        // setIsTableQueueValid(data.isValid); // Uncomment if needed
    } catch (error) {
        console.error('Error fetching queue data:', error);
        // SetQueueError(error.data)
    }
};


  // Fetch data initially and set interval for every minute
  useEffect(() => {
    if (isTableQueueValid) {
      setTimeout(() => {
        fetchQueueData(); // Fetch data on component mount
      }, 2000);
}
  
  }, []);





  
  // Initialize Firebase App
  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);
    const [messages, setMessages] = useState([]);
    // const [currentToken, setCurrentToken] = useState(null);
    // const [permission, setPermission] = useState(Notification.permission);
  
  
  
  
    
  const handleQueuefcm = () =>{
      
setTimeout(async() => {
  const tablequeuetoken = localStorage.getItem('tableQueue')
  if (tablequeuetoken ) {
    const getfcmToken = localStorage.getItem('fcmToken')
    try {
      const resp = await axios.post('https://qosko.in/hotel/api/staff-update-fcm-key',{"fcm": getfcmToken },{
        headers:{
          "Authorization":`Token ${tablequeuetoken}`,
          "Content-Type":'application/json'
        }
      })
      console.log('responce fcm ', resp.data)
      
    } catch (error) {
      console.log("error while fcm api call", error)
    }
   
  }
}, 2000);
    }



  useEffect(()=>{
     // Handle messages when received
    //  handleQueuefcm() 
     const unsubscribe = onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);

      appendMessage(payload);
  });

  // Cleanup on unmount
  return () => {
      unsubscribe();
      localStorage.removeItem('sentToServer')
  };
  },[messaging])

  const appendMessage = (payload) => {
    setMessages((prevMessages) => [...prevMessages, payload]);
};






// console.log('messages from props in queue list ', messages[0]?.notification?.body);
// console.log('messages from props in queue all ', messages);

useEffect(()=>{
  console.log('messages from props in queue update useeffect with dependence messages ', messages[0]?.notification?.body);
 
  if (messages[0]?.notification?.body === "staff_queue_update") {
    fetchQueueData();
    
}
},[messages])



  if (loader) {
    return(
      <div style={{display:'flex', height:'100vh', flexDirection:'column', justifyContent:'center'}}>
         <div role="status" style={{display:'flex', justifyContent:'center', alignItems:'center',}}>
      <svg aria-hidden="true" className="inline w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
      </svg>
  </div>
      <div style={{color:'black', alignContent:'center',marginRight:'auto', marginLeft:'auto', paddingTop:'12px'}} >Loading...</div>
      </div>
     

    )
  }

  return (
    <>
      <div className="sticky top-0 z-10 bg-white shadow-md">
        {/* Add your logo or nav items here */}
        <nav className="bg-[#f5f5f5] shadow-md p-4 pt-4  flex flex-col sm:flex-row items-center justify-between">
          <div className="flex items-center">
          {/* <img src={logos} alt="logo" className="h-24 w-28 bg-white" /> */}
          {/* <h1>Qosko</h1> */}
          </div>
          <div className="flex flex-col gap-2">
            <a
              className="text-xl font-bold mb-4 sm:mb-0"
              href={hotel.website}
              target="_blank"
              rel="noopener noreferrer"
            >
              {hotel.name}
            </a>

            <div className="flex space-x-4 mb-4 sm:mb-0">
              <a
                href={hotel.facebook}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook className="text-blue-600" size={30} />
              </a>
              <a
                href={hotel.instagram}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram className="text-pink-600" size={30} />
              </a>
              <a
                href={hotel.snapchat}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaSnapchat className="text-yellow-500" size={30} />
              </a>
              <a href={hotel.twitter} target="_blank" rel="noopener noreferrer">
                <FaTwitter className="text-blue-400" size={30} />
              </a>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row sm:space-x-4 items-center w-full sm:w-auto">
            {!isOtpValid && (
              <>
                {!showOtpInput && (
                //   <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 w-full sm:w-auto">
                //     {isTableQueueValid && (
                //       <div className="mt-4 text-center">
                //         <p>Your token number: {queueToken}</p>
                //         <p>Estimated time: {estimatedTime}</p>
                //       </div>
                //     )}

                //     {!isTableQueueValid && (
                //       <button
                //         className="bg-blue-500 text-white px-4 py-2 rounded w-full sm:w-auto"
                //         onClick={() => setShowQueuePopup(true)}
                //       >
                //         Join Queue
                //       </button>
                //     )}

                //     <button
                //       className="bg-blue-500 text-white px-4 py-2 rounded w-full sm:w-auto"
                //       onClick={handleJoinTable}
                //     >
                //       Join Table
                //     </button>
                //   </div>


                <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 w-full sm:w-auto">
                {isTableQueueValid && (
                  <div className="mt-4 text-center">
                    <p>Your token number: {queueToken}</p>
                    <p>Number Of People ahead : {countHead}</p>
                    <p>Estimated time: {estimatedTime}</p>
                    <button
                      className="ml-2 p-1 text-black"
                      onClick={fetchQueueData} // Call the refresh function when clicked
                    >
                      <FaSync className={`transition-transform duration-300`}
                        style={{ transform: `rotate(${rotation}deg)` }} />
                    </button>
                  </div>
                )}
          <div className="flex  gap-4">
                {!isTableQueueValid && (
                  <button
                    className="bg-blue-500 text-white px-4 py-2 rounded w-full sm:w-auto"
                    onClick={() => setShowQueuePopup(true)}
                  >
                    Join Queue
                  </button>
                )}
          
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded w-full sm:w-auto"
                  onClick={handleJoinTable}
                >
                  Join Table
                </button>
          </div>
              </div>



                )}

                {showOtpInput && (
                  <div className="flex flex-col sm:flex-col space-y-2 sm:space-y-0 sm:space-x-4 items-center w-full sm:w-auto">
                    <div className="gap-2 flex">
                      <input
                        type="number"
                        value={otp}
                        onChange={handleOtpChange}
                        placeholder="Enter OTP"
                        className="border border-gray-300 p-2 rounded w-full sm:w-auto"
                      />
                      <button
                        type="button"
                        onClick={handleOtpSubmit}
                        className="bg-green-500 text-white px-1 py-1 rounded w-full sm:w-auto"
                      >
                        Verify OTP
                      </button>
                      <button
                      type="button"
                      onClick={() => setShowOtpInput(false)}
                      className="bg-gray-300 text-black px-4 py-1 rounded ml-1"
                    >
                      <IoClose/>
                      {/* Cancel */}
                    </button>
                    </div>
                    <div className="text-[14px]">
                      {otpError ? (
                        <p className="text-red-500 text-sm mt-2">{otpError}</p>
                      ) : (
                        <p className="text-gray-700 mt-2">
                          To access additional services, please ask OTP from the
                          hotel staff.
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}

            {isOtpValid && (
              <div className="flex flex-col sm:flex-row overflow-x-auto space-y-2 sm:space-y-0 w-full md:gap-2 sm:w-auto">
                <div className="flex justify-between ">
                  <p className="text-gray-700 mt-2">Table No:- {tableassign}</p>
                  <button
                    className="group inline-block rounded-full bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 p-[1px] hover:text-white focus:outline-none focus:ring active:text-opacity-75"
                    onClick={()=> { handleLogOut()}}
                  >
                    <span className="block rounded-full bg-white px-4 py-2 text-sm font-medium group-hover:bg-transparent">
                      Logout
                    </span>
                  </button>
                </div>
            

                <div>
                  <div className="flex space-x-2 overflow-x-auto scrollbar-hide whitespace-nowrap">
                    <button
                      className="p-2 rounded border text-gray-700"
                      onClick={fetchTableStatus}
                    >
                      <FaSync
                        className={`transition-transform duration-300`}
                        style={{ transform: `rotate(${rotation}deg)` }}
                      />
                    </button>
                    {[
                      {
                        name: "waiter",
                        icon: <FaPerson />,
                      },
                      {
                        name: "water",
                        icon: <FaGlassWhiskey />,
                      },
                      {
                        name: "tissue",
                        icon: <FaToiletPaper />,
                      },
                      {
                        name: "cleaning",
                        icon: <FaBroom />,
                      },
                    ].map((service) => {
                      const serviceState = serviceTimers[service.name] || {};
                      const elapsedTime = serviceState.elapsedTime || 0;

                      return (
                        <button
                          key={service.name}
                          className={`text-gray-700 px-4 flex gap-2 py-2 rounded ${getButtonColor(
                            elapsedTime
                          )}`}
                          onClick={() => {
                            handleApiCall(service.name.toLocaleLowerCase());
                          }}
                        >
                          <span>{service.icon}</span>
                          <span>{service.name}</span>
                          {/* timmer remove as per update review */}
                          {/* {elapsedTime > 0 && (
                            <span className="ml-2">
                              {" "}
                              ({Math.floor(elapsedTime / 60)}:
                              {elapsedTime % 60 < 10 ? "0" : ""}
                              {elapsedTime % 60})
                            </span>
                          )} */}
                        </button>
                      );
                    })}
                  </div>
                </div>

                <button
                  className="inline-block rounded border border-[#60A5FA] px-12 py-3 text-sm font-medium text-[#60A5FA] hover:bg-[#60A5FA] hover:text-white focus:outline-none focus:ring active:bg-[#60A5FA]"
                  onClick={handlePayBill}
                >
                  Pay Bill
                </button>
                <button
                  className="inline-block rounded border border-green-600 px-12 py-3 text-sm font-medium text-green-600 hover:bg-green-600 hover:text-white focus:outline-none focus:ring active:bg-green-500"
                  onClick={() => window.open(hotel.googlereview, "_blank")}
                >
                  Rate Us On Google
                </button>
              </div>
            )}
          </div>

          {showQueuePopup && (
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-xl mb-4">Join Queue</h2>
                <form onSubmit={handleQueueSubmit}>
                  <div className="mb-4">
                    <label htmlFor="name" className="block mb-2">
                      Name:
                    </label>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="border border-gray-300 p-2 rounded w-full"
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="mobileNo" className="block mb-2">
                      Mobile No:
                    </label>
                    <input
                      type="text"
                      value={mobileNo}
                      onChange={(e) => setMobileNo(e.target.value)}
                      className="border border-gray-300 p-2 rounded w-full"
                    />
                    {mobileError && (
                      <p className="text-red-500">{mobileError}</p>
                    )}
                  </div>
                  <div className="mb-4">
                    <label htmlFor="tableFor" className="block mb-2 ">
                      Table For:
                    </label>
                    <input
                      type="text"
                      value={tableFor}
                      onChange={(e) => setTableFor(e.target.value)}
                      className="border border-gray-300 p-2 rounded w-full"
                    />
                  </div>
                  <div className="flex justify-end">
                    <button
                      type="submit"
                      className="bg-blue-500 text-white px-4 py-2 rounded"
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      onClick={() => setShowQueuePopup(false)}
                      className="bg-gray-300 text-black px-4 py-2 rounded ml-2"
                    >
                      Cancel
                    </button>
                  </div>
                  <p className="text-red-500">{queueError}</p>
                  </form>
              </div>
            </div>
          )}
          {showPaymentPopup && (
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-xl mb-4">Payment Details</h2>
                {billDetails && (
                  <>
                    <p className="mb-2">
                      Bill Amount: {billDetails.bill.amount}₹
                    </p>
                    <div className="flex justify-center">
                      {billDetails.bill.amount === 0 ? (
                        <a
                          href={billDetails.bill.pay_any_url}
                          className="bg-green-500 text-white px-4 py-2 rounded"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Pay
                        </a>
                      ) : billDetails.bill.amount > 0 ? (
                        <a
                          href={billDetails.bill.pay_exact_url}
                          className="bg-blue-500 text-white px-4 py-2 rounded"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Pay Exact Amount
                        </a>
                      ) : (
                        // Handle other amounts as needed, for example:
                        <p>Please check your payment options.</p>
                      )}
                    </div>
                  </>
                )}
                <button
                  onClick={handleClosePaymentPopup}
                  className="mt-4 bg-gray-300 text-black px-4 py-2 rounded"
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </nav>
        <h1 className="text-3xl font-bold text-center mt-2 mb-8">
          {/* {hotel.name} no need as per sir requirement  */}
           Menu 🍽️
        </h1>
        {/* Add more nav items if needed */}

        <div className="flex overflow-x-auto whitespace-nowrap md:pl-[100%]  md:justify-center space-x-3 mb-8 scrollbar-hide">
          {categories.map((category, index) => (
            <button
              key={index} // Adding unique key
              className={`px-4 py-2 text-sm font-semibold border-b-4 ${
                activeCategory === category
                  ? "border-green-500 text-green-500"
                  : "border-transparent text-gray-600"
              } transition-colors duration-300 ease-in-out`}
              onClick={() => setActiveCategory(category)}
            >
              {category}
            </button>
          ))}
        </div>
      </div>

      <div className="container mx-auto p-4">
        {/* Menu Items */}
        <div className="grid grid-cols-1 gap-6">
          {filteredItems.map((item, index) => (
            <div className="bg-white p-4 rounded-lg shadow-lg flex flex-col">
           
            <div
              key={index}
              className=" flex flex-col sm:flex-row"
            >
              {/* Menu Item Details */}
              <div className="flex-grow ">
                {/* Name and Veg/Non-Veg Indicator */}
                <div className="flex items-center gap-5">
                  {item.category !== "Drinks" && (
                    <div className="flex items-center">
                      <span
                        className={`border-2 w-4 h-4 flex justify-center items-center ${
                          item.is_veg ? "border-green-500" : "border-red-500"
                        } rounded`}
                      >
                        <span
                          className={`inline-block w-2.5 h-2.5 rounded-full ${
                            item.is_veg ? "bg-green-500" : "bg-red-500"
                          }`}
                        ></span>
                      </span>
                    </div>
                  )}
                  <div className="flex items-center justify-between w-full">

                  <h2 className="text-xl font-bold text-green-600">
                    {item.name}
                  </h2>
                  {
                    item.spice_level === 0 ? '': ''                                        
                  }
                  {
                    item.spice_level === 1 ? <>
                    <GiChiliPepper className="text-red-700 h-8 w-7"/>
                    </>: ''                                        
                  }
                  {
                    item.spice_level === 2 ? <div className="flex">
                    <GiChiliPepper className="text-red-700 h-8 w-7"/>
                    <GiChiliPepper className="text-red-700 h-8 w-7"/>
                    </div>: ''                                        
                  }
                  {
                    item.spice_level === 3 ? <div className="flex">
                    <GiChiliPepper className="text-red-700 h-8 w-7"/>
                    <GiChiliPepper className="text-red-700 h-8 w-7"/>
                    <GiChiliPepper className="text-red-700 h-8 w-7"/>
                    </div>: ''                                        
                  }
                  

                  <p className="text-gray-800 font-semibold mt-2">
                    {item.price ? item.price.toFixed(2) : "N/A"}₹
                  </p>
                  </div>
                </div>
                {/* <p className="text-gray-600 mt-2">{item.desc}</p> */}
              </div>
            </div>
            


            {
              item.social_platform && (

                <SocialMediaPosts url={item.social_platform_link} platform={item.social_platform}/>  
              )
            }

             </div>
          ))}
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default MenuItem;
