import React from 'react';
import { InstagramEmbed, FacebookEmbed, YouTubeEmbed } from 'react-social-media-embed';

const SocialMediaPosts = ({url, platform}) => {
  return (
    <div className="max-w-md mx-auto my-8 space-y-8">
      {/* Instagram Embed */}
      {
        platform === "instagram" ? <div>
        <InstagramEmbed
          url={`${url}`}
          width="100%"
        />
      </div> : ''
      }
     

      {/* Facebook Embed */}
      {
         platform === "facebook" ? <div>
        <FacebookEmbed
           url={`${url}`}
          width="100%"
        />
      </div> : ''

      }
     

      {/* YouTube Embed */}
      {
         platform === "youtube" ?<div>
        <YouTubeEmbed
          url={`${url}`}
          width="100%"
        />
      </div> : ''
      }
      
    </div>
  );
};

export default SocialMediaPosts;
