import React from 'react';
import logo from '../Assets/logo_full.jpeg.jpg';

const Footer = () => {
  return (
    <div>
      <footer className="bg-[#f5f5f5] fixed bottom-0 w-full z-10">
        <div className="mx-auto max-w-screen-xl px-2 py-0 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between">
            <div className="flex justify-center text-teal-600 sm:justify-start">
              <img src={logo} alt="logo" className="h-20 w-28" />
            </div>
            <p className="mb-2 text-center text-sm text-gray-500 lg:mt-0 lg:text-right">
              Copyright &copy; 2024. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
