import axios from 'axios';
import React, { useState, useEffect } from 'react';
import FirebaseMessaging from '../FirebaseMessaging/FirebaseMessaging.tsx';

const TableDashboard = ({messages}) => {
  const [tables, setTables] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(null);

 const getloginToken = localStorage.getItem('loginToken')

  const fetchData = async () => {
    try {
      const response = await axios.get("https://qosko.in/tables/api/get-table-list", {
        headers: {
          Authorization: `Token ${getloginToken}`
        }
      });
      console.log("table data ", response.data.tables);
      setTables(response.data.tables);
    } catch (error) {
      console.error('Error fetching table data', error);
      
      // setTables(data);
    }
  };
  useEffect(()=>{
    fetchData();
  },[])
  
  useEffect(() => {
    // console.log('messages from props ', messages)
    // const intervalId = setInterval(fetchData, 5000);
    // return () => clearInterval(intervalId);
    if (messages[0]?.notification?.body === 'staff_service_update') {
        fetchData();
    }
  }, [messages]);

  
  console.log('messages from props ', messages[0]?.notification?.body);
  const toggleDropdown = (tableId) => {
    setDropdownOpen(dropdownOpen === tableId ? null : tableId);
  };

  const setBillAmount = async (tableId) => {
    const amount = prompt('Enter bill amount', '100');
    if (amount) {
      const headers = {
        'Authorization': `Token ${getloginToken}`,
        'Content-Type': 'multipart/form-data'
      };
  
      const formData = new FormData();
      formData.append('table_num', tableId); // Use table_num instead of table
      formData.append('amount', amount);
  
      try {
        const res = await axios.post(
          'https://qosko.in/tables/api/table-charge-bill',
          formData,
          { headers }
        );
        console.log("setbill res", res.data)
        if (res.data) {
          setTables((prevTables) =>
            prevTables.map((t) =>
              t.id === res.data.table.number ? {
                ...t,
                // status: res.data.table.status === "Engaged", // Assuming 0 means "Vacant"
                // otp: res.data.table.otp,
                amount: res.data.table.amount,
              } : t
            )
          );
          toggleDropdown(null)
        }
        // window.location.reload(); // Reload to reflect the updated bill
      } catch (error) {
        console.error('Error setting bill amount', error);
      }
    }
  };
  

  const checkIn = async (tableId) => {
    
    const headers = {
      'Authorization': `Token ${getloginToken}`,
      'Content-Type': 'multipart/form-data'
    };
  
    const formData = new FormData();
    console.log('table number ',tableId)
    formData.append('table_num', tableId);
  
    try {
      const response = await axios.post(
        'https://qosko.in/tables/api/table-check-in', 
        formData, 
        { headers }
      );
  
      const updatedTable = response.data.table;
      
      setTables((prevTables) =>
        prevTables.map((table) =>
          table.id === tableId
            ? { ...table, 
                otp: updatedTable.otp, 
                status: updatedTable.status_name === 'Engaged',
                services: updatedTable.services,
                hotel: updatedTable.hotel,
                amount: updatedTable.amount,

              }
            : table
        )
      );
      toggleDropdown(null)
      // window.location.reload()
      console.log('Table checked in successfully', response.data);
    } catch (error) {
      console.error('Error during check-in', error);
    }
  };
  
  const checkOut = async (tableId) => {
    const headers = {
      'Authorization': `Token ${getloginToken}`,
      'Content-Type': 'multipart/form-data',
    }; 
  
    const formData = new FormData();
    console.log('checkout table',tableId)
    formData.append('table_num', tableId); // Assuming `tableId` corresponds to the table number
  
    try {
      const response = await axios.post('https://qosko.in/tables/api/table-check-out', formData, { headers });
      
      if (response.data && response.data.table) {
        console.log('checkout table ', response.data)
        const table = response.data.table;
        // Update the table in your state
        setTables((prevTables) =>
          prevTables.map((t) =>
            t.id === table.number ? {
              ...t,
              status: table.status === "Vacant", // Assuming 0 means "Vacant"
              otp: table.otp,
              amount: table.amount,
            } : t
          )
        );
      }
      toggleDropdown(null)

      // window.location.reload()
    } catch (error) {
      console.error('Error during check-out', error);
    }
  };


  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
    



      <div className="flex-1 overflow-y-auto bg-white scrollbar-hide">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
          {tables.map((table) => (
            <div
              key={table.id}
              className={`p-4 border rounded-lg relative ${table.status ? 'bg-green-200' : 'bg-gray-100'}`}
            >
              <div>
                <div>Table No. {table.number}</div>
                {table.status ? (
                  <div>
                    <div className='flex gap-10 align-middle self-center'>
                    <div>OTP: {table.otp}</div>  
                     <div className='flex gap-4'>
                      {
                        table.waiter_request ? <div className="w-4 h-4 bg-blue-700 rounded-full"></div> :''
                      }
                      {
                        table.water_request ?  <div className="w-4 h-4 bg-[#648382] rounded-full"></div> : ''
                      }
                     
                      {
                        table.tissue_request ? <div className="w-4 h-4 bg-yellow-700 rounded-full"></div> : ''
                      }
                      
                      {
                        table.table_clean_request ? <div className="w-4 h-4 bg-red-700 rounded-full"></div> : ''
                      }
                      
                      </div> 
                    </div>
                    <div>Bill: {table.amount}</div>
                  </div>
                ) : ''}
              </div>

              <div className="btn-container">
                <button
                  className="absolute top-2 right-2 text-lg"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleDropdown(table.id);
                  }}
                  aria-label={`Options for Table ${table.number}`}
                >
                  ⋮
                </button>
                {dropdownOpen === table.id && (
                  <div className="absolute right-2 top-8 bg-white border rounded shadow-lg z-20">
                    {table.status ? (
                      <>
                        <button
                          onClick={() => checkOut(table.number)}
                          className="block px-4 py-2 text-sm w-full text-left"
                        >
                          Check Out
                        </button>
                        <button
                          onClick={() => setBillAmount(table.number)}
                          className="block px-4 py-2 text-sm w-full text-left"
                        >
                          Set Bill Amount
                        </button>
                      </>
                    ) : (
                      <button
                        onClick={() => checkIn(table.number)}
                        className="block px-4 py-2 text-sm w-full text-left"
                      >
                        Check In
                      </button>
                    )}
                    <a href="/" className="block px-4 py-2 text-sm">
                      Block
                    </a>
                    <a href="/" className="block px-4 py-2 text-sm">
                      Unblock
                    </a>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* <FirebaseMessaging/> */}
    </div>
  );
};

export default TableDashboard;
