import React,{useState, useEffect} from 'react'
import axios from 'axios';

function ServiceUpdate() {

    const [tables, setTables] = useState([]);

    const fetchData = async () => {
        try {
          const response = await axios.get("https://qosko.in/tables/api/get-table-list", {
            headers: {
              Authorization: `Token ${getloginToken}`
            }
          });
          console.log("table data ", response.data.tables);
          setTables(response.data.tables);
        } catch (error) {
          console.error('Error fetching table data', error);
          
          // setTables(data);
        }
      };
      
      useEffect(() => {
        fetchData();
        // const intervalId = setInterval(fetchData, 5000);
        // return () => clearInterval(intervalId);
      }, []);

    const getloginToken = localStorage.getItem('loginToken')


    const handleServiceRequest = (tableNumber, serviceName) => {
        // Create a new FormData object
        const formData = new FormData();
      
        // Append table number and service to the formData
        formData.append('table_num', tableNumber);
        formData.append('service', serviceName);
      
        // Replace with your actual API endpoint
        const apiEndpoint = 'https://qosko.in/tables/api/table-reset-service';
      
        // Example API call using axios with headers
        axios.post(apiEndpoint, formData, {
          headers: {
            'Authorization': `Token ${getloginToken}`,
            'Content-Type': 'multipart/form-data',
          }
        })
          .then(response => {
            console.log('Request successful:', response.data);
            fetchData()
            // Handle successful response (e.g., notify the user)
          })
          .catch(error => {
            console.error('Error:', error);
            // Handle error (e.g., show error message)
          });
      };
      
    
  const sortedTables = [...tables].sort((a, b) => {
    const aRequestTimes = [
      a.waiter_requestTime,
      a.water_request_time,
      a.tissue_request_time,
      a.table_clean_request_time,
    ].filter(time => time !== null);
    const bRequestTimes = [
      b.waiter_request_time,
      b.water_request_time,
      b.tissue_request_time,
      b.table_clean_request_time,
    ].filter(time => time !== null);

    const aEarliestRequestTime = aRequestTimes.length > 0 ? new Date(Math.min(...aRequestTimes.map(time => new Date(time)))) : null;
    const bEarliestRequestTime = bRequestTimes.length > 0 ? new Date(Math.min(...bRequestTimes.map(time => new Date(time)))) : null;

    if (!aEarliestRequestTime && !bEarliestRequestTime) return 0;
    if (!aEarliestRequestTime) return 1;
    if (!bEarliestRequestTime) return -1;

    return aEarliestRequestTime - bEarliestRequestTime;
  });



  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
    <div className="sticky top-0 py-2 px-4 bg-white shadow mb-2 z-50">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-2">
     {/* Waiter Call Box */}
<div className="p-2 sm:p-4 border bg-blue-100 rounded-lg shadow-md mb-2 sm:mb-4">
<div className="font-bold text-lg sm:text-xl text-blue-700 mb-1 sm:mb-2">Waiter Call Request</div>
<span className="text-gray-600 font-semibold text-sm sm:text-base">Table No. </span>
<div className="flex gap-2 sm:gap-3 overflow-x-auto scrollbar-hide py-1 sm:py-2">
  {sortedTables.filter(table => table.waiter_request).map(table => (
    <button 
      key={table.id}
      onClick={() => handleServiceRequest(table.number, 'waiter')} 
      className="font-bold text-sm sm:text-lg bg-blue-500 text-white px-2 py-1 sm:px-4 sm:py-2 rounded-full shadow hover:bg-blue-600 transition duration-300">
      {table.number}
    </button>
  ))}
</div>
</div>

{/* Water Box */}
<div className="p-2 sm:p-4 border bg-[#b9e2e0] rounded-lg shadow-md mb-2 sm:mb-4">
<div className="font-bold text-lg sm:text-xl text-[#648382] mb-1 sm:mb-2">Water Request</div>
<span className="text-gray-600 font-semibold text-sm sm:text-base">Table No. </span>
<div className="flex gap-2 sm:gap-3 overflow-x-auto scrollbar-hide py-1 sm:py-2">
  {sortedTables.filter(table => table.water_request).map(table => (
    <button 
      key={table.id}
      onClick={() => handleServiceRequest(table.number, 'water')} 
      className="font-bold text-sm sm:text-lg bg-green-500 text-white px-2 py-1 sm:px-4 sm:py-2 rounded-full shadow hover:bg-green-600 transition duration-300">
      {table.number}
    </button>
  ))}
</div>
</div>

{/* Tissue Box */}
<div className="p-2 sm:p-4 border bg-yellow-100 rounded-lg shadow-md mb-2 sm:mb-4">
<div className="font-bold text-lg sm:text-xl text-yellow-700 mb-1 sm:mb-2">Tissue Request</div>
<span className="text-gray-600 font-semibold text-sm sm:text-base">Table No. </span>
<div className="flex gap-2 sm:gap-3 overflow-x-auto scrollbar-hide py-1 sm:py-2">
  {sortedTables.filter(table => table.tissue_request).map(table => (
    <button 
      key={table.id}
      onClick={() => handleServiceRequest(table.number, 'tissue')} 
      className="font-bold text-sm sm:text-lg bg-yellow-500 text-white px-2 py-1 sm:px-4 sm:py-2 rounded-full shadow hover:bg-yellow-600 transition duration-300">
      {table.number}
    </button>
  ))}
</div>
</div>

{/* Table Clean Box */}
<div className="p-2 sm:p-4 border bg-red-100 rounded-lg shadow-md mb-2 sm:mb-4">
<div className="font-bold text-lg sm:text-xl text-red-700 mb-1 sm:mb-2">Table Clean Request</div>
<span className="text-gray-600 font-semibold text-sm sm:text-base">Table No. </span>
<div className="flex gap-2 sm:gap-3 overflow-x-auto scrollbar-hide py-1 sm:py-2">
  {sortedTables.filter(table => table.table_clean_request).map(table => (
    <button 
      key={table.id}
      onClick={() => handleServiceRequest(table.number, 'cleaning')} 
      className="font-bold text-sm sm:text-lg bg-red-500 text-white px-2 py-1 sm:px-4 sm:py-2 rounded-full shadow hover:bg-red-600 transition duration-300">
      {table.number}
    </button>
  ))}
</div>
</div>

      </div>
    </div>
    </div>
  )
}

export default ServiceUpdate