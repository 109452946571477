import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, deleteToken, onMessage } from 'firebase/messaging';
import { firebaseConfig, vapidKey } from '../config.ts';

// Initialize Firebase App
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

// Type definition for message payload
type MessagePayload = {
    notification?: {
        title?: string;  // Allow title to be undefined
        body?: string;   // Allow body to be undefined
    };
    [key: string]: any;
};

const FirebaseMessaging = () => {
    const [currentToken, setCurrentToken] = useState<string | null>(null);
    const [messages, setMessages] = useState<MessagePayload[]>([]);
    const [permission, setPermission] = useState(Notification.permission);

    useEffect(() => {
        resetUI();

        // Handle messages when received
        const unsubscribe = onMessage(messaging, (payload) => {
            console.log('Message received. ', payload);
            appendMessage(payload);
        });

        // Cleanup on unmount
        return () => {
            unsubscribe();
        };
    }, []);

    const sendTokenToServer = (currentToken: string) => {
        if (!isTokenSentToServer()) {
            console.log('Sending token to server...', currentToken);
            // TODO: Send the current token to your server.
            setTokenSentToServer(true);
        } else {
            console.log('Token already sent to server so won\'t send it again unless it changes');
        }
    };

    const resetUI = () => {
        clearMessages();
        setCurrentToken("Loading......"); // Set  instead of "loading..."
        
        // Get registration token
        getToken(messaging, { vapidKey })
            .then((token) => {
                if (token) {
                    sendTokenToServer(token);
                    setCurrentToken(token);
                } else {
                    console.log('No token available, request permission');
                    setPermission(Notification.permission);
                }
            })
            .catch((err) => {
                console.log('Error retrieving token: ', err);
                setCurrentToken(null); // Use null for error case
            });
    };

    const requestPermission = () => {
        console.log('Requesting permission...');
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                console.log('Permission granted!');
                resetUI();
                setPermission('granted');
            } else {
                console.log('Permission denied.'); // Notify the user
                setPermission('denied');
            }
        });
    };

    const deleteFirebaseToken = () => {
        getToken(messaging)
            .then((token) => {
                if (token) {
                    deleteToken(messaging).then(() => {
                        console.log('Token deleted.');
                        setCurrentToken(null);
                        resetUI();
                    });
                }
            })
            .catch((err) => {
                console.log('Error deleting token: ', err);
            });
    };

    const appendMessage = (payload: MessagePayload) => {
        setMessages((prevMessages) => [...prevMessages, payload]);
    };

    const clearMessages = () => {
        setMessages([]);
    };

    const isTokenSentToServer = () => {
        return window.localStorage.getItem('sentToServer') === '1';
    };

    const setTokenSentToServer = (sent: boolean) => {
        window.localStorage.setItem('sentToServer', sent ? '1' : '0');
    };

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <header className="bg-green-700 text-white p-6">
                <h1 className="text-2xl">Firebase Messaging</h1>
            </header>
            <main className="flex-grow p-6">
                <div className="bg-white shadow-md rounded p-6">
                    {permission !== 'granted' ? (
                        <div className="flex flex-col items-start">
                            <h2 className="text-xl mb-4">Needs Permission</h2>
                            <button
                                onClick={requestPermission}
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            >
                                Request Permission
                            </button>
                        </div>
                    ) : (
                        <div>
                            {currentToken ? (
                                <div className="mb-4">
                                    <h2 className="text-xl">Registration Token</h2>
                                    <p className="break-all">{currentToken}</p>
                                    <button
                                        onClick={deleteFirebaseToken}
                                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4"
                                    >
                                        Delete Token
                                    </button>
                                </div>
                            ) : (
                                <p>No token available. Please request permission to generate one.</p>
                            )}

                            <div className="mt-6">
                                <h3 className="text-xl">Messages</h3>
                                {messages.length > 0 ? (
                                    messages.map((message, index) => (
                                        <div key={index} className="bg-gray-200 p-4 rounded my-2">
                                            <h4 className="font-bold">{message.notification?.title || 'No Title'}</h4>
                                            <p>{message.notification?.body || 'No Body'}</p>
                                            <pre>{JSON.stringify(message, null, 2)}</pre>
                                        </div>
                                    ))
                                ) : (
                                    <p>No messages received yet.</p>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </main>
        </div>
    );
};

export default FirebaseMessaging;
